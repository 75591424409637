const actionTypes = {
  GET_DEMOS_LOADING: "GET_DEMOS_LOADING",
  GET_DEMOS_FULFILLED: "GET_DEMOS_FULFILLED",
  GET_DEMOS_REJECTED: "GET_DEMOS_REJECTED",

  GET_EXPERIENCES_LOADING: "GET_EXPERIENCES_LOADING",
  GET_EXPERIENCES_FULFILLED: "GET_EXPERIENCES_FULFILLED",
  GET_EXPERIENCES_REJECTED: "GET_EXPERIENCES_REJECTED",

  GET_CERTIFICATES_LOADING: "GET_CERTIFICATES_LOADING",
  GET_CERTIFICATES_FULFILLED: "GET_CERTIFICATES_FULFILLED",
  GET_CERTIFICATES_REJECTED: "GET_CERTIFICATES_REJECTED",

  GET_CONFIGURATIONS_LOADING: "GET_CONFIGURATIONS_LOADING",
  GET_CONFIGURATIONS_FULFILLED: "GET_CONFIGURATIONS_FULFILLED",
  GET_CONFIGURATIONS_REJECTED: "GET_CONFIGURATIONS_REJECTED",

  GET_PORTFOLIOS_LOADING: "GET_PORTFOLIOS_LOADING",
  GET_PORTFOLIOS_FULFILLED: "GET_PORTFOLIOS_FULFILLED",
  GET_PORTFOLIOS_REJECTED: "GET_PORTFOLIOS_REJECTED"
};

export default actionTypes;
